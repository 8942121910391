import { BREAKPOINTS } from '@typeform/ginger/dist/constants/breakpoints'
import React, { memo, useCallback, useRef, useState } from 'react'
import { useMedia } from 'react-use'
import { GridContainer } from 'components/atoms/layout/'

import { TTestimonialsModuleProps } from './types'
import TestimonialCard from './testimonial-card'
import {
  BrandHeadline,
  CarouselContainer,
  CarouselSlider,
  ControlNext,
  ControlPrevious,
  NavContainer,
  NavWrapper,
  TestimonialsSection,
} from './testimonials-module.styles'
import { SCROLL_STATE, TESTIMONIALS_PER_PAGE } from './constants'

const getPaddedString = (number: number) => String(number).padStart(2, '0')

const getPageInfo = (currentIndex: number, totalCount: number) =>
  `${getPaddedString(currentIndex + 1)}/${getPaddedString(totalCount)}`

const TestimonialsModule = ({
  headline,
  testimonials,
}: TTestimonialsModuleProps) => {
  const containerRef = useRef<HTMLInputElement | null>(null)
  const onScrollTimeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  )
  const [visibleMobileTestimonialIndex, setVisibleMobileTestimonialIndex] =
    useState(0)
  const [containerScrollState, setContainerScrollState] = useState('start')
  const isMobile = useMedia(`(max-width: ${BREAKPOINTS.lg - 1}px)`, false)

  const testimonialsCount = testimonials.length

  const hasMoreThanOnePage = isMobile
    ? testimonialsCount > 1
    : testimonialsCount > TESTIMONIALS_PER_PAGE

  const handleOnClickPrevious = useCallback(() => {
    // @ts-expect-error
    containerRef.current.scrollLeft -=
      // @ts-expect-error
      containerRef.current.firstChild.offsetWidth
  }, [])

  const handleOnClickNext = useCallback(() => {
    // @ts-expect-error
    containerRef.current.scrollLeft +=
      // @ts-expect-error
      containerRef.current.firstChild.offsetWidth
  }, [])

  const handleScroll = useCallback(() => {
    onScrollTimeoutIdRef.current && clearTimeout(onScrollTimeoutIdRef.current)

    const scrollTimeoutId = setTimeout(() => {
      const containerElement = containerRef.current

      // @ts-expect-error
      const itemWidth = containerElement.scrollWidth / testimonials.length

      // @ts-expect-error
      const visibleIndex = Math.floor(containerElement.scrollLeft / itemWidth)

      // @ts-expect-error
      const isScrollAtStart = containerElement.scrollLeft === 0
      const isScrollAtEnd =
        // @ts-expect-error
        containerElement.scrollWidth ===
        // @ts-expect-error
        containerElement.scrollLeft + containerElement.clientWidth

      let scrollState

      if (isScrollAtStart) {
        scrollState = SCROLL_STATE.START
      } else if (isScrollAtEnd) {
        scrollState = SCROLL_STATE.END
      } else {
        scrollState = SCROLL_STATE.MIDDLE
      }

      setContainerScrollState(scrollState)
      setVisibleMobileTestimonialIndex(visibleIndex)
    }, 100)

    onScrollTimeoutIdRef.current = scrollTimeoutId

    return () => {
      clearTimeout(scrollTimeoutId)
    }
  }, [testimonials])

  return (
    <TestimonialsSection>
      {headline && (
        <GridContainer>
          <BrandHeadline size={0}>{headline}</BrandHeadline>
        </GridContainer>
      )}
      <CarouselContainer>
        <CarouselSlider
          ref={containerRef}
          onScroll={hasMoreThanOnePage ? handleScroll : undefined}
        >
          {testimonials.map(review => (
            <TestimonialCard
              key={review.id}
              testimonialsCount={testimonialsCount}
              {...review}
            />
          ))}
        </CarouselSlider>
      </CarouselContainer>
      {hasMoreThanOnePage && (
        <NavContainer>
          <NavWrapper>
            <ControlPrevious
              disabled={containerScrollState === SCROLL_STATE.START}
              aria-hidden
              onClick={handleOnClickPrevious}
            >
              {'<-'}
            </ControlPrevious>
            {isMobile &&
              getPageInfo(visibleMobileTestimonialIndex, testimonialsCount)}
            <ControlNext
              disabled={containerScrollState === SCROLL_STATE.END}
              aria-hidden
              onClick={handleOnClickNext}
            >
              {'->'}
            </ControlNext>
          </NavWrapper>
        </NavContainer>
      )}
    </TestimonialsSection>
  )
}

TestimonialsModule.displayName = 'TestimonialsModule'

export default memo(TestimonialsModule)
