import { GRID_UNIT } from '@typeform/ginger/dist/constants/design'
import mediaqueries from '@typeform/ginger/dist/util/mediaqueries'
import {
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import { Picture } from 'components/atoms/images'
import styled from 'styled-components'

import {
  TESTIMONIALS_GAP_DESKTOP,
  TESTIMONIALS_PER_PAGE,
  TESTIMONIAL_WIDTH_DESKTOP,
} from './constants'
import { TTestimonialsCount } from './types'

const getMiddlePoint = ({
  testimonialsCount,
}: {
  testimonialsCount: TTestimonialsCount
}) => {
  const visibleCount = Math.min(testimonialsCount, TESTIMONIALS_PER_PAGE)
  const totalItemWidth = visibleCount * TESTIMONIAL_WIDTH_DESKTOP
  const totalGapWidth = (visibleCount - 1) * TESTIMONIALS_GAP_DESKTOP

  const middlePoint = (totalItemWidth + totalGapWidth) / 2

  return middlePoint
}

export const Avatar = styled(Picture)`
  width: 160px;
  height: 160px;
  border-radius: 50px;
`

export const TestimonialContainer = styled.blockquote<{
  testimonialsCount: TTestimonialsCount
}>`
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${GRID_UNIT * 4}px;
  margin: 0;
  padding: 0;
  scroll-snap-align: center;
  flex-direction: column;

  ${mediaqueries.lg`
    width: ${TESTIMONIAL_WIDTH_DESKTOP}px;
    flex-direction: row;

    &:first-child {
      margin-left: calc(50vw - ${getMiddlePoint}px)
    }

    &:last-child {
      margin-right: ${({
        testimonialsCount,
      }: {
        testimonialsCount: TTestimonialsCount
      }) =>
        testimonialsCount <= TESTIMONIALS_PER_PAGE
          ? 0
          : `calc(50vw - ${getMiddlePoint({ testimonialsCount })}px)`}
    }
  `}
`

export const TestimonialContent = styled.p`
  display: flex;
  flex-direction: column;
  gap: ${GRID_UNIT * 2}px;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: ${GRID_UNIT * 4}px;
  padding-right: ${GRID_UNIT * 4}px;
  text-align: center;

  ${mediaqueries.lg`
    text-align: left;
    padding: 0px;
  `}
`

export const Quote = styled.q`
  font-family: ${fontFamily.apercuPro};
  font-size: ${fontSize.xLarge};
  font-weight: ${fontWeight.regular};
  line-height: ${lineHeight.medium};
  display: block;
`
export const Reviewer = styled.b`
  font-family: ${fontFamily.apercuPro};
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.regular};
  line-height: ${lineHeight.medium};
  display: block;
`
