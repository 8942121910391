import { GRID_UNIT } from '@typeform/ginger/dist/constants/design'

export const TESTIMONIALS_PER_PAGE = 2.5
export const TESTIMONIALS_GAP_DESKTOP = GRID_UNIT * 4
export const TESTIMONIAL_WIDTH_DESKTOP = 442
export const SCROLL_STATE = {
  START: 'start',
  MIDDLE: 'middle',
  END: 'end',
}
