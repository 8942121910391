import { GRID_UNIT } from '@typeform/ginger/dist/constants/design'
import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import mediaqueries from '@typeform/ginger/dist/util/mediaqueries'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import styled, { keyframes } from 'styled-components'
import { Headline } from '@typeform/ginger/dist/components/typography/headline'
import { GridContainer } from 'components/atoms/layout/'

import { TESTIMONIALS_GAP_DESKTOP } from './constants'

export const TestimonialsSection = styled.section`
  background-color: ${NeutralColors.GreyPaper};
  padding-bottom: ${GRID_UNIT * 8}px;
  padding-top: ${GRID_UNIT * 5}px;

  ${mediaqueries.lg`
    padding-bottom: ${GRID_UNIT * 12}px;
    padding-top: ${GRID_UNIT * 12}px;
  `}
`
export const BrandHeadline = styled(Headline)`
  text-align: center;
  grid-column: 1 / -1;
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.xLarge};
  line-height: ${lineHeight.xSmall};
  padding-bottom: 34px;

  ${mediaqueries.lg`
    grid-column: 4 / 10;
    font-size: ${fontSize.x2Large};
    padding-bottom: ${GRID_UNIT * 11}px;
  `};
`

export const CarouselContainer = styled.div`
  overflow: initial;
  position: relative;
`

export const CarouselSlider = styled.div`
  color: ${NeutralColors.Ink};
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  ${mediaqueries.lg`
    gap: ${TESTIMONIALS_GAP_DESKTOP}px;
  `}
`
export const NavContainer = styled(GridContainer)`
  margin-top: ${GRID_UNIT * 7}px;
  ${mediaqueries.lg`
    display: grid;
    margin-top: ${GRID_UNIT * 11}px;
  `}
`

export const NavWrapper = styled.div`
  display: flex;
  grid-column: 1 / -1;
  justify-content: center;
  align-items: center;
  gap: ${GRID_UNIT}px;
  color: ${NeutralColors.Ink};
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.regular};
  line-height: ${lineHeight.medium};

  ${mediaqueries.lg`
    font-size: ${fontSize.x2Large};
    justify-content: space-between;
  `}
`

const pointingArrowAnimation = (amount: number) => keyframes`
  0% {
    transform: translateX(0)
  }
  50% {
    transform: translateX(${amount}px)
  }
  100% {
    transform: translateX(0)
  }
`

const Control = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: ${GRID_UNIT / 2}px;
  transition: opacity 0.4s ease-out,
    transform 0.8s cubic-bezier(0.76, 0, 0.24, 1);
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;

  &:disabled {
    opacity: 0.1;
    pointer-events: none;
    transform: translate(0, 0);
  }

  &:hover {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.37, 0, 0.63, 1);
  }
`

export const ControlPrevious = styled(Control)`
  svg {
    transform: rotate(180deg);
  }

  ${mediaqueries.lg`
    &:hover {
      animation-name: ${pointingArrowAnimation(4)};
    }
  `}
`

export const ControlNext = styled(Control)`
  ${mediaqueries.lg`
    &:hover {
      animation-name: ${pointingArrowAnimation(-4)};
    }
  `}
`
