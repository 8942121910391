import React, { useMemo } from 'react'

import { TTestimonialCardProps, TConstructReviewerStringParams } from './types'
import {
  Reviewer,
  Avatar,
  Quote,
  TestimonialContainer,
  TestimonialContent,
} from './testimonial-card.styles'

const constructReviewerString = ({
  name = '',
  jobTitle,
  companyName,
}: TConstructReviewerStringParams) => {
  const nameAndJobTitle: string[] = []
  const finalReviewerArray: string[] = []

  name && nameAndJobTitle.push(name)
  jobTitle && nameAndJobTitle.push(jobTitle)

  nameAndJobTitle.length > 0 &&
    finalReviewerArray.push(nameAndJobTitle.join(' - '))

  companyName && finalReviewerArray.push(companyName)

  return finalReviewerArray.join(', ')
}

const TestimonialCard = ({
  avatar,
  review,
  testimonialsCount,
  name = '',
  jobTitle = '',
  companyName = '',
}: TTestimonialCardProps) => {
  const reviewer = useMemo(
    () =>
      constructReviewerString({
        name,
        jobTitle,
        companyName,
      }),
    [name, jobTitle, companyName]
  )

  return (
    <TestimonialContainer testimonialsCount={testimonialsCount}>
      <Avatar src={avatar} alt={reviewer} />
      <TestimonialContent>
        <Reviewer>{reviewer}</Reviewer>
        <Quote>{review}</Quote>
      </TestimonialContent>
    </TestimonialContainer>
  )
}

export default TestimonialCard
